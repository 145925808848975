import { IContractProviderResponse } from '@omnicar/sam-types'
import IApiModel, { createModel, IApiField } from './apiModel'
import { contactPersonModel, contactPersonValueDefaults } from './contactPerson'
import { countryModel, countryValueDefaults } from './country'
import {
  requiredBoolean,
  requiredNumber,
  requiredPrimaryKey,
  requiredString,
  urlPattern,
  validateAdditionalEmails,
  providerCheckBoxesCompatibilityValidation,
  providerCheckBoxesCompatibilityError,
} from './validations'

export interface IContractProviderRequestPatch extends IContractProviderResponse {}

export interface IContractProviderRequestPost extends IContractProviderResponse {}

export interface IContractProviderRequestGet {
  contractProviderId?: number
  search?: string
  country?: string
  state?: string
}

// Default Values
export const contractProviderValueDefaults: IContractProviderResponse = {
  contractProviderId: -1,
  administrativeName: '',
  contactFormUrl: '',
  contactPerson: contactPersonValueDefaults,
  cvrCode: '',
  country: countryValueDefaults,
  omnicarFee: 0,
  paymentGateways: ['Stripe'],
  finalizedDate: null,
  logo: '',
  postmarkFromEmail: 'no-reply@fragus.com',
  providerIdentifier: '',
  sendWelcomeLetter: false,
  showRegistrationSelectionPage: false,
  termsOfTradeRef: '',
  visibleToOtherChildren: true,
  webCalcEnabled: true,
  webTitle: null,
  isUseWhiteBGOnWeb: false,
  isForceAllowCustomContracts: false,
  isShowLogoOnWeb: false,
  webLogoBanner: null,
  webLogoSquare: null,
  disableContractTemplates: false,
  productsEnabled: false,
  parentProviderId: null,
  autoSettlement: true,
  state: 'Active',
  isAllowContractsWithoutVehiclePriceModel: false,
  fragusContactPersonName: null,
  providerGroup: null,
  providerCategory: null,
  additionalEmails: null,
  isUsingV4PricingTool: false,
  isUseV4PTOnlyForSigning: false,
  isLoadOptionalProviderCustomization: false,
  webCalcTrackingPixelOrderConfirmationUrl: '',
  webCalcTrackingPixelOrderConfirmationLastUpdated: '',
  inheritParentStylingWebcalc: false,
  generalConditions: '',
  optionsConditions: '',
}

// API Model
export const apiModel: IApiModel = {
  name: 'contractProviderModel',
  fields: [
    {
      name: 'contractProviderId',
      uiType: 'string',
      title: 'Provider ID',
      validation: [requiredPrimaryKey],
      renderer: ({ value }) => {
        return '00' + value
      },
    },
    {
      name: 'parentProviderId',
      uiType: 'string',
      title: 'Main Provider',
      smaller: true,
    },
    {
      name: 'administrativeName',
      uiType: 'string',
      title: 'Name',
      validation: [requiredString],
    },
    {
      name: 'cvrCode',
      uiType: 'string',
      title: 'CVR Code',
      validation: [requiredString],
    },
    {
      name: 'isoName',
      uiType: 'string',
      title: 'Country',
      relation: 'country',
    },
    {
      name: 'country',
      uiType: 'string',
      title: 'Country',
      relation: 'Country',
    },
    {
      name: 'contactFormUrl',
      uiType: 'string',
      title: 'Contact Form URL',
      validation: [requiredString, urlPattern],
    },
    {
      name: 'contactPerson',
      uiType: 'string',
      title: 'Contact Person',
      relation: 'ContactPerson',
    },

    {
      name: 'additionalEmails',
      uiType: 'string',
      title: 'Additional emails for mailing(separator ;)',
      validation: [
        {
          message: 'Incorrect email',
          type: 'pattern',
          valid: ({ value }) => validateAdditionalEmails(value as string),
        },
      ],
    },
    {
      name: 'finalizedDate',
      uiType: 'date',
      title: 'Finalized Date',
      validation: [
        {
          message: 'Required field',
          type: 'required',
          valid: ({ value }) => value !== undefined,
        },
        {
          message: 'Must be correct date format',
          type: 'pattern',
          valid: ({ value }) => {
            let valid = true

            if (value !== undefined && value !== null) {
              // @TODO check date correctly formated
              valid = true
            }

            return valid
          },
        },
      ],
    },

    {
      name: 'logo',
      uiType: 'image',
      title: 'Logo',
    },
    {
      name: 'omnicarFee',
      uiType: 'number',
      title: 'Fragus Fee [%]',
      validation: [requiredNumber],
    },
    {
      name: 'postmarkFromEmail',
      uiType: 'boolean',
      title: '',
      validation: [requiredBoolean],
    },
    {
      name: 'providerIdentifier',
      uiType: 'string',
      title: 'Provider ID',
    },
    {
      name: 'sendWelcomeLetter',
      uiType: 'boolean',
      title: '',
      validation: [requiredBoolean],
    },
    {
      name: 'webCalcEnabled',
      uiType: 'boolean',
      title: 'Is Webcalc / Webshop enabled',
      validation: [requiredBoolean],
      smaller: true,
      isShowCheckboxInProviderList: true,
      //checkboxCaptionShort: 'Yes', // 'WC/WS',
    },
    {
      name: 'productsEnabled',
      uiType: 'boolean',
      title: 'Allow products',
      validation: [requiredBoolean],
      smaller: true,
      isShowCheckboxInProviderList: true,
      //checkboxCaptionShort: 'Yes', // 'P',
    },
    {
      name: 'isUsingV4PricingTool',
      uiType: 'boolean',
      title: 'Use V4PricingTool ONLY contracts',
      validation: [
        requiredBoolean,
        {
          message: providerCheckBoxesCompatibilityError,
          type: 'data-compatibility',
          valid: providerCheckBoxesCompatibilityValidation,
        },
      ],
      smaller: true,
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'isUseV4PTOnlyForSigning',
      uiType: 'boolean',
      title: 'Use ONLY V4PricingTool for signing contracts',
      validation: [
        requiredBoolean,
        {
          message: providerCheckBoxesCompatibilityError,
          type: 'data-compatibility',
          valid: providerCheckBoxesCompatibilityValidation,
        },
      ],
      smaller: true,
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'showRegistrationSelectionPage',
      uiType: 'boolean',
      title: '',
      validation: [requiredBoolean],
    },
    {
      name: 'termsOfTradeRef',
      uiType: 'string',
      title: 'Terms of Trade',
      // validation: [requiredString],  // Note: ToT requrement turned off for time being.
    },
    {
      name: 'webTitle',
      uiType: 'string',
      title: '',
    },
    {
      name: 'isUseWhiteBGOnWeb',
      uiType: 'boolean',
      title: 'Use WhiteBG-theme in Admin',
      validation: [requiredBoolean],
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'isShowLogoOnWeb',
      uiType: 'boolean',
      title: 'Show dealer logo in Admin',
      validation: [requiredBoolean],
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'webLogoBanner',
      uiType: 'image',
      title: 'Web Logo Banner',
    },
    {
      name: 'webLogoSquare',
      uiType: 'image',
      title: 'Web Logo Square',
    },
    {
      name: 'disableContractTemplates',
      uiType: 'boolean',
      title: 'Disable contract Templates',
      validation: [requiredBoolean],
      smaller: true,
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'autoSettlement',
      uiType: 'boolean',
      title: 'Auto-Settlement enabled',
      validation: [requiredBoolean],
      smaller: true,
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'isForceAllowCustomContracts',
      uiType: 'boolean',
      title: 'Force Custom contracts',
      validation: [
        requiredBoolean,
        {
          message: providerCheckBoxesCompatibilityError,
          type: 'data-compatibility',
          valid: providerCheckBoxesCompatibilityValidation,
        },
      ],
      smaller: true,
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'isAllowContractsWithoutVehiclePriceModel',
      uiType: 'boolean',
      title: 'Allow contracts without price-model',
      validation: [requiredBoolean],
      smaller: true,
      isShowCheckboxInProviderList: true,
    },
    {
      name: 'fragusContactPersonName',
      uiType: 'string',
      title: 'Fragus Contact name', //'Fragus Contact person name',
    },
    {
      name: 'providerGroup',
      uiType: 'string',
      title: 'Group',
    },
    {
      name: 'providerCategory',
      uiType: 'string',
      title: 'Category',
    },
    {
      name: 'inheritParentStylingWebcalc',
      uiType: 'boolean',
      title: 'Inherit parent styling in Webcalc',
      validation: [requiredBoolean],
      // isShowCheckboxInProviderList: true,
    },
    { name: 'providerCustomization' },
  ],
  view: {
    providerPage: [
      {
        name: 'providerIdentifier',
      },
      {
        name: 'parentProviderId',
      },
      {
        name: 'administrativeName',
      },
      {
        name: 'cvrCode',
      },
      {
        name: 'omnicarFee',
      },
      {
        name: 'isoName',
      },
      { name: 'fragusContactPersonName' },
      {
        name: 'providerGroup',
      },
      {
        name: 'providerCategory',
      },
      { name: 'productsEnabled' },

      { name: 'isUsingV4PricingTool' },
      { name: 'isUseV4PTOnlyForSigning' },

      { name: 'disableContractTemplates' },
      { name: 'isShowLogoOnWeb' },
      { name: 'isUseWhiteBGOnWeb' },
      { name: 'webCalcEnabled' },
      { name: 'isForceEnableCustomContract' },
      { name: 'isAllowContractsWithoutVehiclePriceModel' },
      { name: 'autoSettlement' },
    ],
  },
}

const countryRelation = countryModel()
const contactPersonRelation = contactPersonModel()

export const contractProviderModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IContractProviderResponse>(view, viewContext, apiModel, contractProviderValueDefaults, {
    Country: { name: 'Country', model: countryRelation },
    ContactPerson: { name: 'ContactPerson', model: contactPersonRelation },
  })
