import QueryString from 'querystring'
import { IJsonStatus, IRequestParams, requestJson } from '@omnicar/sam-tfetch'
import {
  ApiError,
  IAccessKey,
  IAddNewV4DealerRequestFromClient,
  IAddNewV4DealerResponseToClient,
  ICheckIfProviderConnectedRequestFromClient,
  ICheckIfProviderConnectedResponseToClient,
  IConnectProviderToV4RequestFromClient,
  IConnectProviderToV4ResponseToClient,
  IContractOptionResponse,
  IContractProviderResponse,
  IContractTemplateCloneRequest,
  IContractTemplateResponse,
  ICountryResponse,
  IDisconnectV4DealerFromProviderRequestFromClient,
  IDisconnectV4DealerFromProviderResponseToClient,
  IListV4DealersRequestFromClient,
  IListV4DealersResponseToClient,
  ILoginRequest,
  ILoginResponse,
  IOutageResponse,
  IProductContractTemplateResponse,
  IReleaseNoteRequest,
  IReleaseNoteResponse,
  IReleaseNotesResponse,
  IReleaseVersionRequest,
  IReleaseVersionResponse,
  IsoLocale,
  IStripeExternalAccount,
  IStripeExternalAccountResponse,
  IStripeRequest,
  IStripeResponse,
  ITranslationApplication,
  ITranslationRequest,
  ITranslationResponse,
  ITranslationValueRequest,
  ITranslationValueResponse,
  IUserResponse,
  IVatLookupRequest,
  IVatLookupResponse,
  OutageSeverityLevel as SeverityLevel,
} from '@omnicar/sam-types'
import { ICreateTestContractProvider } from '@omnicar/sam-types/types/admin/contractProvider'
import { IWarrantyResponse } from '@omnicar/sam-types/types/warranty'
import { setReduxOutage } from 'actions/outageActions'
import { IWarrantyGet } from 'apiModels/warranty'
import { clearTokens, getRefreshToken, handleRefreshToken, sleep } from 'utils/auth'
import { getTokenRefreshStarted, setTokenRefreshStarted } from 'utils/localStorage'
import { showError, showOutage, showWarning } from 'utils/toastify'
import {
  IContractOptionRequestGet,
  IContractOptionRequestPatch,
  IContractOptionRequestPost,
} from '../apiModels/contractOption'
import {
  IContractProviderRequestGet,
  IContractProviderRequestPatch,
  IContractProviderRequestPost,
} from '../apiModels/contractProvider'
import {
  IContractTemplateRequestGet,
  IContractTemplateRequestPost,
  IGenericContractTemplateRequestPatch,
  IProductContractTemplateRequestPatch,
  IProductContractTemplateRequestPost,
} from '../apiModels/contractTemplate'
import { IStripeRequestGet } from '../apiModels/stripe'
import { IUserRequestGet } from '../apiModels/user'
import { resolveToken } from '../selectors/user'
import store from '../utils/store'

const t = (text: string) => text

// Courtesy function to provide leading path to API
export const getBaseUrl = () => {
  return `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`
}

const appPrefix = 'superadmin'
// @TODO - Help me Erik
function handleErrorAlerts<T>(response: IJsonStatus<T, ApiError>) {
  const { errorData, networkError, statusCode } = response
  const userAuthErrors: string[] = [
    'GENERIC_USER_NOT_FOUND',
    'GENERIC_UNAUTHORIZED',
    'GENERIC_INVALID_TOKEN',
    'GENERIC_FORBIDDEN_ROLE',
  ]

  if (errorData && errorData.message === 'GENERIC_OUTAGE' && 'params' in errorData) {
    const outage = ((errorData as unknown) as {
      params: IOutageResponse
    }).params
    setOutageFromResponse(outage)
    return
  }

  // On error show notifification to user
  if (networkError) {
    if (!window.navigator.onLine) {
      showWarning(t('Invalid user'))
    }
  } else if (errorData && userAuthErrors.includes(errorData.message)) {
    showWarning(t('Invalid user'))
  } else if (errorData && errorData.message) {
    showError(errorData.message)
  } else if (errorData) {
    showError(JSON.stringify(errorData))
  } else if (statusCode) {
    if (statusCode >= 500) {
      showError(`${t('Internal Server Error')}: ${statusCode}`)
    } else if (statusCode >= 400) {
      showError(`${t('Error in server call')}: ${statusCode}`)
    }
  }
}

// Using requestJson from "tfetch" by Erik Beuschau - https://github.com/OmniCar/tfetch
export interface IApiRequest<R> extends IRequestParams<R> {
  disableErrorHandling?: boolean
}

function setOutageFromResponse(outage: IOutageResponse) {
  store.dispatch(setReduxOutage(outage || null))

  switch (outage.severityLevel) {
    case SeverityLevel.Low:
      showOutage(outage.message || 'Unknown outage. Please try again in a moment.', 'info')
      break
    case SeverityLevel.Medium:
      showOutage(outage.message || 'Unknown outage. Please try again in a moment.', 'warning')
      break
    case SeverityLevel.High:
      showOutage(outage.message || 'Unknown outage. Please try again in a moment.', 'error')
      break
  }
}

export type TApiResponseType<T> = IJsonStatus<T, ApiError>

export async function apiRequest<R, T>(
  options: IApiRequest<R>,
  isRefreshTokenRequest?: boolean,
): Promise<TApiResponseType<T>> {
  const baseUrl = getBaseUrl()
  const { disableErrorHandling, url, ...rest } = { disableErrorHandling: false, ...options }
  const extraHeaders = options.extraHeaders || [{ key: 'access-token', value: resolveToken() }]
  const config = { url: `${baseUrl}/${url}`, extraHeaders, ...rest }
  let response: IJsonStatus<T, ApiError> = await requestJson<T, ApiError>(config)
  // intercept invalid token

  if (response.statusCode === 403 && response.errorData?.message === 'GENERIC_INVALID_TOKEN') {
    const allowRetry = await interceptInvalidToken(isRefreshTokenRequest)

    // retry request
    response = allowRetry
      ? await requestJson<T, ApiError>({
          ...config,
          // we must use new access-token
          extraHeaders: extraHeaders?.map((h) =>
            h.key === 'access-token' ? { ...h, value: resolveToken() || '' } : h,
          ),
        })
      : response
  }

  if (!disableErrorHandling && (response.networkError || response.errorData)) {
    handleErrorAlerts<T>(response)
  }

  let result = response
  if (response && 'data' in response && typeof response.data === 'object' && isOutageResponse<T>(response)) {
    if (response.data.outage) {
      if ('headerText' in response.data.outage) {
        store.dispatch(setReduxOutage(response.data.outage || null))
      }
    } else {
      store.dispatch(setReduxOutage(null))
    }

    result = { statusCode: response.statusCode, data: response.data.body }
  }

  return result
}

// TODO: figure out how to change 'outage: any' to specific type
const isOutageResponse = <T>(response: any): response is { data: { outage: any; body?: T } } =>
  'outage' in response.data

const apiPostRequest = async (url: string, body?: FormData): Promise<Response> => {
  let response = await fetch(`${getBaseUrl()}/${url}`, {
    method: 'POST',
    body,
    headers: { 'access-token': resolveToken() || '' },
  })

  const invalidToken = response.status === 403 && (await response.json()).message === 'GENERIC_INVALID_TOKEN'
  // intercept invalid token
  if (invalidToken) {
    const allowRetry = await interceptInvalidToken()
    // retry request
    // we must use new access-token
    response = allowRetry
      ? await fetch(`${getBaseUrl()}/${url}`, {
          method: 'POST',
          body,
          headers: { 'access-token': resolveToken() || '' },
        })
      : response
  }

  return response
}

/**
 * @param isRefreshTokenRequest
 */
const interceptInvalidToken = async (isRefreshTokenRequest?: boolean): Promise<boolean> => {
  let tokenRefreshed = false

  if (isRefreshTokenRequest) {
    clearTokens()
  } else {
    const now = new Date().getTime()
    const waitForTokenRefresh = (now - getTokenRefreshStarted()) / 1000 < 2 // wait if refresh requested less than 2 seconds ago

    if (waitForTokenRefresh) {
      // wait 2 seconds for token refresh before retry
      await sleep(2000)
    } else {
      // refresh token
      setTokenRefreshStarted(now)
      await handleRefreshToken()
    }
    tokenRefreshed = true
  }
  return tokenRefreshed
}

/********************************************************
 * File downloading URL's
 *
 ********************************************************/
const accessTokenParam = () => `?access-token=${resolveToken() || ''}`

export const modelExportUrl = (country: string) =>
  `${getBaseUrl()}/${appPrefix}/export/models/xls/${country}${accessTokenParam()}`

export const pricesExportUrl = (providerId: number) =>
  `${getBaseUrl()}/${appPrefix}/export/prices/xls/${providerId}${accessTokenParam()}`

export const warrantyPricesExportUrl = (providerId: number) =>
  `${getBaseUrl()}/${appPrefix}/export/warrantyprices/xls/${providerId}${accessTokenParam()}`

export const warrantyExternalPricesExportUrl = (providerId: number) =>
  `${getBaseUrl()}/${appPrefix}/export/externalwarrantyprices/xls/${providerId}${accessTokenParam()}`

/********************************************************
 * Sync Warranties API Requests
 *
 ********************************************************/

export const onboardWarranties = (providerId: number) => apiPostRequest(`v1/onboardwarranties/${providerId}`)

export const syncWarrantyCatalogue = (country: string) =>
  apiPostRequest(`${appPrefix}/import/syncwarrantymodels/${country}`)

/********************************************************
 * Contract Provider API Requests
 *
 ********************************************************/
export function getContractProviders(request?: IContractProviderRequestGet) {
  const queryParams = request ? { ...request } : {}
  const queryString = QueryString.stringify(queryParams)

  return apiRequest<IContractProviderRequestGet, IContractProviderResponse[]>({
    url: `v1/contractproviders?${queryString}`,
    method: 'GET',
  })
}

export function getContractProviderById(request: IContractProviderRequestGet) {
  return apiRequest<IContractProviderRequestGet, IContractProviderResponse>({
    url: `v1/contractproviders/${request.contractProviderId}`,
    method: 'GET',
  })
}

export function patchContractProvider(request: IContractProviderRequestPatch) {
  return apiRequest<IContractProviderRequestPatch, IContractProviderResponse>({
    url: `v1/contractproviders/${request.contractProviderId}`,
    method: 'PATCH',
    body: request,
  })
}

export function postContractProvider(request: IContractProviderRequestPost) {
  if (request.contractProviderId === -1) {
    delete request.contractProviderId
  }

  return apiRequest<IContractProviderRequestPost, IContractProviderResponse>({
    url: `v1/contractproviders`,
    method: 'POST',
    body: request,
  })
}

export function createDefaultContractProvider(request: ICreateTestContractProvider) {
  return apiRequest<ICreateTestContractProvider, IContractProviderResponse>({
    url: `v1/defaultcontractprovider`,
    method: 'POST',
    body: request,
    timeout: 600000,
  })
}

export function updateContractProviderState(providerId: number) {
  return apiRequest<never, IContractProviderResponse>({
    url: `v1/contractproviders/${providerId}/state`,
    method: 'PATCH',
    timeout: 300000,
  })
}

export function deleteContractProvider(providerId: number) {
  return apiRequest<never, { message: string }>({
    url: `v1/contractproviders/${providerId}`,
    method: 'DELETE',
    timeout: 60000,
  })
}

/********************************************************
 * Config API Requests
 *
 ********************************************************/

export function getOfferExpirationDays() {
  return apiRequest<never, number>({
    url: `${appPrefix}/config/offerexpirationdays`,
    method: 'GET',
  })
}

/********************************************************
 * Country API Requests
 *
 ********************************************************/

export function getAllCountries() {
  return apiRequest<never, ICountryResponse[]>({
    url: `${appPrefix}/countries`,
    method: 'GET',
  })
}

/********************************************************
 * Contract Template API Requests
 *
 ********************************************************/
export function getContractTemplates(request: IContractTemplateRequestGet) {
  return apiRequest<IContractTemplateRequestGet, IContractTemplateResponse[]>({
    url: `v1/contractproviders/${request.providerId}/contracttemplates`,
    method: 'GET',
  })
}

export function getContractTemplateById(request: IContractTemplateRequestGet) {
  return apiRequest<IContractTemplateRequestGet, IContractTemplateResponse | IProductContractTemplateResponse>({
    url: `v1/contracttemplates/${request.id}`,
    method: 'GET',
  })
}

export function patchContractTemplate(request: IGenericContractTemplateRequestPatch) {
  return apiRequest<IGenericContractTemplateRequestPatch, IContractTemplateResponse>({
    url: `v1/contracttemplates/${request.id}`,
    method: 'PATCH',
    body: request,
  })
}

export function patchProductContractTemplate(request: IProductContractTemplateRequestPatch) {
  return apiRequest<IProductContractTemplateRequestPatch, IProductContractTemplateResponse>({
    url: `v1/contracttemplates/${request.id}`,
    method: 'PATCH',
    body: request,
  })
}

export function postContractTemplate(request: IContractTemplateRequestPost) {
  if (request.id === -1) {
    delete request.id
  }

  return apiRequest<IContractTemplateRequestPost, IContractTemplateResponse>({
    url: `v1/contracttemplates`,
    method: 'POST',
    body: request,
  })
}

export function postProductContractTemplate(request: IProductContractTemplateRequestPost) {
  if (request.id === -1) {
    delete request.id
  }

  return apiRequest<IProductContractTemplateRequestPost, IProductContractTemplateResponse>({
    url: `v1/product-contracttemplates`,
    method: 'POST',
    body: request,
  })
}

export function deleteContractTemplate(request: IContractTemplateRequestPost) {
  return apiRequest<IContractTemplateRequestPost, IContractTemplateResponse>({
    url: `v1/contracttemplates/${request.id}/delete`,
    method: 'POST',
  })
}

export function postContractTemplateClone(request: IContractTemplateCloneRequest) {
  return apiRequest<IContractTemplateCloneRequest, IContractTemplateResponse>({
    url: `v1/contracttemplates/clone`,
    method: 'POST',
    body: request,
    jsonResponse: false,
  })
}

/********************************************************
 * Contract Option API Requests
 *
 ********************************************************/
export function getContractOptions(request: IContractOptionRequestGet) {
  return apiRequest<IContractOptionRequestGet, IContractOptionResponse[]>({
    url: `v1/contractproviders/${request.contractProviderId}/contractoptions`,
    method: 'GET',
  })
}

export function getContractOptionById(request: IContractOptionRequestGet) {
  return apiRequest<IContractOptionRequestGet, IContractOptionResponse>({
    url: `v1/contractoptions/${request.id}`,
    method: 'GET',
  })
}

export function patchContractOption(request: IContractOptionRequestPatch) {
  return apiRequest<IContractOptionRequestPatch, IContractOptionResponse>({
    url: `v1/contractoptions/${request.id}`,
    method: 'PATCH',
    body: request,
    disableErrorHandling: true,
  })
}

export function postContractOption(request: IContractOptionRequestPost) {
  if (request.id === -1) {
    delete request.id
  }

  return apiRequest<IContractOptionRequestPost, IContractOptionResponse>({
    url: `v1/contractoptions`,
    method: 'POST',
    body: request,
    disableErrorHandling: true,
  })
}

/********************************************************
 * Stripe API Requests
 *
 ********************************************************/
export function getStripe(request: IStripeRequestGet) {
  return apiRequest<IStripeRequestGet, IStripeResponse>({
    url: `v1/contractproviders/${request.contractProviderId}/stripe/account`,
    method: 'GET',
  })
}

export function patchStripe(request: IStripeRequest) {
  return apiRequest<IStripeRequest, IStripeResponse>({
    url: `v1/contractproviders/${request.contractProviderId}/stripe/account`,
    method: 'PATCH',
    body: request,
  })
}

export function postStripeExternalAccount(request: IStripeExternalAccount) {
  return apiRequest<IStripeExternalAccount, IStripeExternalAccountResponse>({
    url: `v1/stripe/external-accounts`,
    method: 'POST',
    body: request,
  })
}

export function getUsersForProviderById(request: IUserRequestGet) {
  return apiRequest<IUserRequestGet, IUserResponse[]>({
    url: `v1/contractproviders/${request.contractProviderId}/users`,
    method: 'GET',
  })
}

/********************************************************
 * File Upload API Requests
 *
 ********************************************************/

export const apiFileUploadRequest = (serverUrl: string, body: FormData): Promise<Response> =>
  apiPostRequest(`v1/${serverUrl}`, body)

export const uploadPrices = (providerId: number, body: FormData): Promise<Response> =>
  apiPostRequest(`${appPrefix}/import/prices/xls/${providerId}`, body)

export const modelUpload = (country: string, body: FormData) =>
  apiPostRequest(`${appPrefix}/import/modelmasterdata/xls/${country}`, body)

export const warrantyPricesUpload = (providerId: number, body: FormData): Promise<Response> =>
  apiPostRequest(`${appPrefix}/import/warrantyprices/xls/${providerId}`, body)

export const warrantyExternalPricesUpload = (providerId: number, body: FormData): Promise<Response> =>
  apiPostRequest(`${appPrefix}/import/externalwarrantyprices/xls/${providerId}`, body)

export function getFileUploadProgress(providerId: number, fileName: string) {
  return apiRequest<never, number>({
    url: `${appPrefix}/import/${providerId}/${fileName}/progress`,
    method: 'GET',
    // We don't want to show errors that we get from this request
    disableErrorHandling: true,
  })
}

/********************************************************
 * VAT/CVR Lookup API Requests
 *
 ********************************************************/
export function getVatInformation(request: IVatLookupRequest) {
  const queryString = QueryString.stringify(request)

  return apiRequest<IVatLookupRequest, IVatLookupResponse>({
    url: `${appPrefix}/vat-lookup?${queryString}`,
    method: 'GET',
  })
}

/********************************************************
 * Warranty API Requests
 *
 ********************************************************/

export function getWarranties() {
  return apiRequest<IWarrantyGet, IWarrantyResponse[]>({
    url: `${appPrefix}/warranties`,
    method: 'GET',
  })
}

/********************************************************
 * User API Requests
 *
 ********************************************************/

export async function login(request: ILoginRequest) {
  return apiRequest<ILoginRequest, ILoginResponse>({
    url: `${appPrefix}/login`,
    method: 'POST',
    body: request,
  })
}

/**
 * refreshes access-token and refresh-token
 * @param token
 */
export const refreshTokens = (token: string) =>
  apiRequest<{ token: string | undefined }, ILoginResponse>(
    {
      url: `${appPrefix}/refresh-token`,
      method: 'POST',
      body: { token },
    },
    true,
  )
/**
 * logs out user (kills session)
 * NOTE: use before refresh-token cleanup
 */
export const logout = () =>
  apiRequest<{ token: string | undefined }, ILoginResponse>({
    url: `${appPrefix}/logout`,
    method: 'POST',
    body: { token: getRefreshToken() },
  })

/********************************************************
 * ReleaseNotes API Requests
 *
 ********************************************************/
// Release Versions
export const getAllReleaseVersions = async (offset: number) =>
  await apiRequest<never, IReleaseVersionResponse[]>({
    url: `${appPrefix}/release/versions?offset=${offset}`,
    method: 'GET',
  })

export const postReleaseVersion = async (request: IReleaseVersionRequest) =>
  await apiRequest<IReleaseVersionRequest, IReleaseVersionResponse>({
    url: `${appPrefix}/release/version`,
    method: 'POST',
    body: request,
  })

export const patchReleaseVersion = async (request: IReleaseVersionResponse) =>
  await apiRequest<IReleaseVersionRequest, IReleaseVersionResponse>({
    url: `${appPrefix}/release/version/${request.releaseVersionId}`,
    method: 'PATCH',
    body: request,
  })

export const publishReleaseVersion = async (request: IReleaseVersionResponse) =>
  await apiRequest<IReleaseVersionRequest, IReleaseVersionResponse>({
    url: `${appPrefix}/release/version/publish/${request.releaseVersionId}`,
    method: 'POST',
  })

// Release Notes
export const getReleaseNotes = async (releaseVersionId: number) =>
  await apiRequest<never, IReleaseNoteResponse[]>({
    url: `${appPrefix}/release/${releaseVersionId}/notes`,
    method: 'GET',
  })

export const postReleaseNote = async (request: IReleaseNoteRequest) =>
  await apiRequest<IReleaseNoteRequest, IReleaseNoteResponse>({
    url: `${appPrefix}/release/note`,
    method: 'POST',
    body: request,
  })

export const patchReleaseNote = async (request: IReleaseNoteResponse) =>
  await apiRequest<IReleaseNoteResponse, IReleaseNoteResponse>({
    url: `${appPrefix}/release/note/${request.releaseNoteId}`,
    method: 'PATCH',
    body: request,
  })

export const deleteReleaseNote = async (releaseNoteId: number) =>
  await apiRequest<never, never>({
    url: `${appPrefix}/release/note/${releaseNoteId}`,
    method: 'DELETE',
  })

export const getReleaseVersionsForView = async (offset: number) =>
  await apiRequest<never, IReleaseNotesResponse[]>({
    url: `${appPrefix}/release-notes?offset=${offset}`,
    method: 'GET',
  })

/********************************************************
 * Translation API Requests
 *
 ********************************************************/

export const getTranslationKeys = async () =>
  await apiRequest<never, ITranslationResponse[]>({
    url: `${appPrefix}/translation/list`,
    method: 'GET',
  })

export const getTranslationValues = async (id: number) =>
  await apiRequest<never, ITranslationValueResponse[]>({
    url: `${appPrefix}/translation/${id}/values`,
    method: 'GET',
  })

export const createTranslationKey = async (body: ITranslationRequest) =>
  await apiRequest<ITranslationRequest, ITranslationResponse>({
    url: `${appPrefix}/translation/key`,
    method: 'POST',
    body,
  })

export const createTranslationValue = async (id: number, body: ITranslationValueRequest) =>
  await apiRequest<ITranslationValueRequest, ITranslationValueResponse>({
    url: `${appPrefix}/translation/${id}/value`,
    method: 'POST',
    body,
  })

export const patchTranslationValue = async (id: number, body: ITranslationValueRequest) =>
  await apiRequest<ITranslationValueRequest, ITranslationValueResponse>({
    url: `${appPrefix}/translation/value/${id}`,
    method: 'PATCH',
    body,
  })

export const getTranslationApplications = async () =>
  await apiRequest<never, ITranslationApplication[]>({
    url: `${appPrefix}/translation/applications`,
    method: 'GET',
  })

export const patchTranslationApplications = async (id: number, body: ITranslationRequest) =>
  await apiRequest<ITranslationRequest, ITranslationResponse>({
    url: `${appPrefix}/translation/${id}`,
    method: 'PATCH',
    body,
  })

export const searchTranslationKeys = async (key: string, isSearchValuesToo: boolean = false) => {
  // Need encoding because of error in request params.
  const encodedSearchParam = encodeURIComponent(key).replace(/%25/g, '%2525')
  return await apiRequest<never, ITranslationResponse[]>({
    url: `${appPrefix}/translation/search/${encodedSearchParam}/${isSearchValuesToo}`,
    method: 'GET',
  })
}

export const getTranslationsWithMissedValues = async () =>
  await apiRequest<never, ITranslationResponse[]>({
    url: `${appPrefix}/translation/missed-values`,
    method: 'GET',
  })

export const translationsImport = (locale: IsoLocale, body: FormData) =>
  apiPostRequest(`${appPrefix}/translation/import/translation-values/${locale}`, body)

export const translationsExportUrl = () => `${getBaseUrl()}/${appPrefix}/export/translations/xls/${accessTokenParam()}`

export const deleteTranslationApplication = async (id: number) =>
  await apiRequest<never, never>({
    url: `${appPrefix}/translation/${id}`,
    method: 'DELETE',
  })

/********************************************************
 * V4PricingTool Dealer / Provider requests
 ********************************************************/

export const v4PTCheckIfProviderConnected = async (request: ICheckIfProviderConnectedRequestFromClient) =>
  await apiRequest<ICheckIfProviderConnectedRequestFromClient, ICheckIfProviderConnectedResponseToClient>({
    url: 'superadmin/v4pt-dealer/check-if-provider-connected',
    method: 'POST',
    body: request,
  })

export const v4PTListV4Dealers = async (request: IListV4DealersRequestFromClient) =>
  await apiRequest<IListV4DealersRequestFromClient, IListV4DealersResponseToClient>({
    url: 'superadmin/v4pt-dealer/list-v4-dealers',
    method: 'POST',
    body: request,
  })

export const v4PTConnectProviderToV4Dealer = async (request: IConnectProviderToV4RequestFromClient) =>
  await apiRequest<IConnectProviderToV4RequestFromClient, IConnectProviderToV4ResponseToClient>({
    url: 'superadmin/v4pt-dealer/connect-provider-to-v4-dealer',
    method: 'POST',
    body: request,
  })

// Note: Unfortanetly, then end-point in V4 only disconnects if 0 is given for the providerId, it
// doesn't work the other way around (eg. giving 0 for the dealerId).
export const v4PTDiconnectV4DealerFromProvider = async (request: IDisconnectV4DealerFromProviderRequestFromClient) =>
  await apiRequest<IDisconnectV4DealerFromProviderRequestFromClient, IDisconnectV4DealerFromProviderResponseToClient>({
    url: 'superadmin/v4pt-dealer/disconnect-v4-dealer-from-provider',
    method: 'POST',
    body: request,
  })

export const v4PTAddProviderToV4 = async (request: IAddNewV4DealerRequestFromClient) =>
  await apiRequest<IAddNewV4DealerRequestFromClient, IAddNewV4DealerResponseToClient>({
    url: 'superadmin/v4pt-dealer/add-provider-to-v4',
    method: 'POST',
    body: request,
  })
/********************************************************
 * AccessKey API Requests
 ********************************************************/

export const createAccessKey = async (body: IAccessKey) =>
  await apiRequest<IAccessKey, IAccessKey>({
    url: `${appPrefix}/access-key`,
    method: 'POST',
    body,
  })

export const updateAccessKey = async (body: IAccessKey) =>
  await apiRequest<IAccessKey, IAccessKey>({
    url: `${appPrefix}/access-key/${body.accessKeyId}`,
    method: 'PATCH',
    body,
  })

export const deleteAccessKey = async (id: number) =>
  await apiRequest<unknown, unknown>({
    url: `${appPrefix}/access-key/${id}`,
    method: 'DELETE',
  })

export const getAccessKeys = async () =>
  await apiRequest<unknown, IAccessKey[]>({
    url: `${appPrefix}/access-keys`,
    method: 'GET',
  })

export const regenerateApiKey = async (accessKeyId: number) =>
  await apiRequest<IAccessKey, IAccessKey>({
    url: `${appPrefix}/access-key/regenerate/${accessKeyId}`,
    method: 'PATCH',
  })

export const createGeneralConditions = async (providerId: number, generalConditions: string) =>
  await apiRequest<unknown, unknown>({
    url: `${appPrefix}/contract-provider/${providerId}/general-conditions`,
    method: 'POST',
    body: { generalConditions },
  })

export const updateGeneralConditions = async (providerId: number, generalConditions: string) =>
  await apiRequest<unknown, unknown>({
    url: `${appPrefix}/contract-provider/${providerId}/general-conditions`,
    method: 'PUT',
    body: { generalConditions },
  })
export const getGeneralConditions = async (providerId: number) => {
  return await apiRequest<unknown, string>({
    url: `${appPrefix}/contract-provider/${providerId}/general-conditions`,
    method: 'GET',
  })
}

export const createOptionsConditions = async (providerId: number, optionsConditions: string) =>
  await apiRequest<unknown, unknown>({
    url: `${appPrefix}/contract-provider/${providerId}/options-conditions`,
    method: 'POST',
    body: { optionsConditions },
  })

export const updateOptionsConditions = async (providerId: number, optionsConditions: string) =>
  await apiRequest<unknown, unknown>({
    url: `${appPrefix}/contract-provider/${providerId}/options-conditions`,
    method: 'PUT',
    body: { optionsConditions },
  })
export const getOptionsConditions = async (providerId: number) =>
  await apiRequest<unknown, string>({
    url: `${appPrefix}/contract-provider/${providerId}/options-conditions`,
    method: 'GET',
  })
